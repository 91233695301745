<template>
  <div>
    <pullLogs></pullLogs>
  </div>
</template>

<script>
import Patients from "@/views/patients/components/patients";
import pullLogs from "@/views/pullLogs/components/pullLogs";
import {
  BCard,
  BRow,
  BFormCheckbox,
  BFormCheckboxGroup,
  BCol,
  BFormDatepicker,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
  BCardHeader,
  BCardBody,
  BModal,
  VBModal,
  BPopover,
  BFormGroup,
  BForm,
} from "bootstrap-vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "index",
  components: {
    pullLogs,
    BFormCheckbox,
    BFormCheckboxGroup,
  },

  data() {
    return {
      check: false,
    };
  },

  methods: {
    toggleView(check) {
      this.check = !this.check;
    },
  },
};
</script>

<style scoped></style>
